.error {
    color: red;
}
.profile{
    max-width: 720px;
    margin: 60px auto;
    padding: 20px;
    /* border-style: solid; */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    text-align: center;
}
.profile h2 {
    margin-bottom: 30px;
}
.profile img {
    margin-bottom: 12px;
}
.profile ul{
    margin-bottom: 10px;
}
.profile textarea {
    width: 290px;
    height: 100px;
}

@media all and (max-width:750px) {
    .profile {
        box-shadow: none;
    }
}
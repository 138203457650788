.application-form {
    max-width: 360px;
    margin: 60px auto;
    padding: 20px;
}

.application-form label {
    display: block;
    margin: 20px auto;
}

.application-form span {
    display: block;
    margin-bottom: 6px;
}

.application-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
}

.application-form a {
    text-align: right;
    margin-bottom: 15px;
    display: block;
    color: #000000
}
.application-form .on-file-resume-label {
    display: flex;
    justify-content: space-between;
    margin: auto auto;
}
.application-form .on-file-resume-span {
    width: 200px;
}
.application {
    max-width: 720px;
    margin: 60px auto;
    padding: 20px;
    /* border-style: solid; */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    text-align: center;
}

.title {
    font-weight: bold;
}

.application-content {
    display: flex;
    justify-content: center;
    margin: 40px auto;
}
.application-details {
    text-align: left;
    align-items: center;
}
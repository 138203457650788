.container {
    display: block;
    max-width: 960px;
    margin: 50px auto;
}
.content {
    text-align: center;
}
@media all and (max-width:750px) {
    
}
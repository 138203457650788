.reset-password-form {
    max-width: 360px;
    margin: 60px auto;
    padding: 20px;
}

.reset-password-form label {
    display: block;
    margin: 25px 15px 25px 0px;
}

.reset-password-form span {
    display: block;
    margin-bottom: 6px;
}

.reset-password-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
}
.container {
    display: flex;
    justify-content: flex-end;
}
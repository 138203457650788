/* navbar styles */
.header {
  padding-top: 30px;
  padding-bottom: 30px;
  /* background-color: transparent; */
  position: relative;
  z-index: 1000;
  box-sizing: border-box;
  display: block;
}
.container {
  padding-inline: 15px;
  max-width: 1200px;
  margin-inline: auto;
}
.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  position: relative;
  float: left;
  margin-right: 10px
}
/* .logo-image {
  width: 200px;
  height: 40px;
} */
.navbar {
    display: flex;
    position: relative;
    float: right;
    align-items: center;
}
.navbar .search-label {
  display: flex;
  justify-content: space-around;
  margin: auto auto;
}
.search-icon {
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
}
.searchbar {
  width: 150px;
  height: 35px;
  margin-right: 20px;
  font-size: 18px;
}
.nav-link {
    padding-right: 20px;
    padding-left: 20px;
    transition-duration: 250ms;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: color;
    font-weight: 500;
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: #222222;
    text-align: left;
    margin-inline: auto;
    box-sizing: border-box;
}
.nav-link:hover {
  color: #F39C12
}
.navbar button {
    margin-left: 20px;
    margin-right: 3px;
}
.navbar a {
  background-color: transparent;
}
.dropdown-container {
  display: block;
  border-style: none;
}
.dropdown {
  flex-direction: column;
}

.menu {
  position: absolute;
  margin: 0 0;
  padding: 0;
  /* border: 1px solid grey; */
  width: 150px;
  background-color: white;
  text-decoration: none;
  border-radius: 5px;
  /* border-color: #F39C12; */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.menu a {
  display: block;
  width: 100%;
  margin-left: 0px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  padding: 10px 15px;
  box-sizing: border-box;
  color: #222222;
}
.menu a:hover {
  background-color: lightgray;
}
.menu .mobile-link {
  display: none;
}
@media all and (max-width:750px) {
  .header {
    padding-top: 20px;
    padding-bottom: 0px;
  }
  .logo-image {
    width: 250px;
    height: 50px;
  }
  .menu .mobile-link {
    display: block;
  }
  .desktop-link {
    display: none;
  }
  .nav-link {
    padding-right: 5px;

  }
}
@media all and (min-width:750px) {
  .full-screen {
    display: none;
  }
}